import { Component } from '@angular/core';

import { BaseSuccessButton } from '../shared/base-button';

@Component({
    selector: 'intello-buttons-submit',
    templateUrl: './buttons-submit.component.html',
    styleUrls: ['./buttons-submit.component.scss'],
    standalone: false
})
export class ButtonsSubmitComponent extends BaseSuccessButton {
}