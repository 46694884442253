<div class="form-fields-text-container">

  <!-- LABEL -->
  <div class="form-fields-text-container__label mb-1 flex flex-row justify-between"
    *ngIf="!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)">
    <span>
      {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
    </span>
    <mat-icon *ngIf="showDescription === true && descriptionType === 'icon'" [svgIcon]="'help'"
      [matTooltip]="description" [matTooltipPosition]="'above'"></mat-icon>

  </div> <!-- /form-fields-text-container__label -->

  <!-- CONTENT -->
  <div class="form-fields-text-container__content">
    <mat-form-field appearance="outline" [ngClass]="{
        'w-full': true,
        'required': control?.hasError('required'),
        'filled': !IsNullOrUndefinedOrEmpty(control?.value),
        'valid': control?.valid && control.dirty && control.hasValidator(Validators.required),
        'disabled': disabled === true
      }">

      <ng-container *ngIf="suffixPosition === 'start'">
        <span *ngIf="!IsNullOrUndefinedOrEmpty(suffix)" matPrefix>
          <ng-container *ngIf="hasIconSuffix; else defaultSuffix">
            <mat-icon class="large" [svgIcon]="suffix"></mat-icon>
          </ng-container>
          <ng-template #defaultSuffix>
            {{ suffix }}
          </ng-template>
        </span>
      </ng-container>

      <!-- INPUT TEXT -->
      <input *ngIf="type === 'text'" [attr.data-testid]="testId" type="text" matInput autocomplete="off"
        [formControl]="getAsFormControl(control)" class="w-full" [pattern]="pattern" [placeholder]="placeholder" />

      <!-- INPUT NUMBER -->
      <input *ngIf="type === 'number'" [attr.data-testid]="testId" type="number" matInput autocomplete="off"
        [formControl]="getAsFormControl(control)" class="w-full" [pattern]="pattern" [placeholder]="placeholder" />

      <!-- INPUT EMAIL -->
      <input *ngIf="type === 'email'" [attr.data-testid]="testId" type="eamil" matInput autocomplete="off"
        [formControl]="getAsFormControl(control)" class="w-full" [pattern]="pattern" [placeholder]="placeholder" />

      <ng-container *ngIf="suffixPosition === 'end'">
        <span *ngIf="!IsNullOrUndefinedOrEmpty(suffix)" matSuffix>
          <ng-container *ngIf="hasIconSuffix; else defaultSuffix">
            <mat-icon class="large" [svgIcon]="suffix"></mat-icon>
          </ng-container>
          <ng-template #defaultSuffix>
            {{ suffix }}
          </ng-template>
        </span>
      </ng-container>

      <!-- DESCRIPTION INLINE -->
      <mat-hint *ngIf="showDescription === true && descriptionType === 'inline'">
        {{ description }}
      </mat-hint>

      <!-- ERRORS: REQUIRED -->
      <mat-error *ngIf="control?.hasError('required')">
        <ng-container *ngIf="!IsNullOrUndefinedOrEmpty(customRequiredMessage); else defaultRequired">
          {{ customRequiredMessage | translate }}
        </ng-container>
        <ng-template #defaultRequired>
          {{ 'Form fields.Text.Errors.Required.Start' | translate }}
          {{ IsNullOrUndefinedOrEmpty(displayName) ? ('Form fields.please provide displayName' | translate) :
          displayName }}
          {{ 'Form fields.Text.Errors.Required.End' | translate }}
        </ng-template>
      </mat-error>

      <!-- ERRORS: NOT CONFIRMED -->
      <mat-error *ngIf="control?.hasError('notConfirmed') && type === 'email'">
        {{ 'Form fields.Text.Errors.Not confirmed.Start' | translate }}
      </mat-error>

      <!-- ERRORS: UNKNOWN -->
      <mat-error *ngIf="control?.hasError('unknown')">
        {{ 'Form fields.Text.Errors.Unknown.Start' | translate }}
      </mat-error>

      <!-- ERRORS: NOT FOUND -->
      <mat-error *ngIf="control?.hasError('notFound')">
        {{ 'Form fields.Text.Errors.Not found.Start' | translate }}
      </mat-error>

      <!-- ERRORS: EMAIL -->
      <mat-error *ngIf="control?.hasError('email') && type === 'email'">
        {{ 'Form fields.Text.Errors.Email.Start' | translate }}
        {{ IsNullOrUndefinedOrEmpty(displayName) ? ('Form fields.please provide displayName' | translate) : displayName
        }}
        {{ 'Form fields.Text.Errors.Email.End' | translate }}
      </mat-error>

      <!-- ERRORS: MAXLENGTH -->
      <mat-error *ngIf="control?.hasError('maxlength')">
        {{ 'Form fields.Text.Errors.Maxlength.Start' | translate }}
        {{ IsNullOrUndefinedOrEmpty(displayName) ? ('Form fields.please provide displayName' | translate) : displayName
        }}
        {{ 'Form fields.Text.Errors.Maxlength.Middle' | translate }}
        {{ maxLength }}
        {{ 'Form fields.Text.Errors.Maxlength.End' | translate }}
      </mat-error>

      <!-- ERRORS: MINLENGTH -->
      <mat-error *ngIf="control?.hasError('minlength')">
        {{ 'Form fields.Text.Errors.Minlength.Start' | translate }}
        {{ IsNullOrUndefinedOrEmpty(displayName) ? ('Form fields.please provide displayName' | translate) : displayName
        }}
        {{ 'Form fields.Text.Errors.Minlength.Middle' | translate }}
        {{ minLength }}
        {{ 'Form fields.Text.Errors.Minlength.End' | translate }}
      </mat-error>

      <!-- ERRORS: MAX -->
      <mat-error *ngIf="control?.hasError('min')">
        {{ 'Form fields.Text.Errors.Max.Start' | translate }}
        {{ IsNullOrUndefinedOrEmpty(displayName) ? ('Form fields.please provide displayName' | translate) : displayName
        }}
        {{ 'Form fields.Text.Errors.Max.Middle' | translate }}
        {{ min }}
        {{ 'Form fields.Text.Errors.Max.End' | translate }}
      </mat-error>

      <!-- ERRORS: MIN -->
      <mat-error *ngIf="control?.hasError('max')">
        {{ 'Form fields.Text.Errors.Min.Start' | translate }}
        {{ IsNullOrUndefinedOrEmpty(displayName) ? ('Form fields.please provide displayName' | translate) : displayName
        }}
        {{ 'Form fields.Text.Errors.Min.Middle' | translate }}
        {{ max }}
        {{ 'Form fields.Text.Errors.Min.End' | translate }}
      </mat-error>

      <!-- ERRORS: PATTERN -->
      <mat-error *ngIf="control?.hasError('pattern')">
        {{ patternErrorMessage }}
      </mat-error>

      <!-- TODO ADD CUSTOM ERRORS -->

      <!-- HINT -->
      <mat-hint align="end" class="max-length-info" *ngIf="lengthHint === true && !IsNullOrUndefined(maxLength)">
        <span class="text-xs text-gray-700">
          {{ IsNullOrUndefined(control?.value) ? '0' : control?.value?.length?.toString() }}
          / {{ maxLength | number:'':'en-US' }}
        </span>
      </mat-hint>

      <mat-hint align="start" class="info-text" *ngIf="!IsNullOrUndefined(hint)">
        <span class="text-xs text-gray-700">
          {{ hint }}
        </span>
      </mat-hint>
    </mat-form-field>
  </div> <!-- /form-fields-text-container__content -->
</div> <!-- /form-fields-text-container -->