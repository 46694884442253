import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseTextFormFieldComponent } from '../base/base-form-field.component';

@Component({
    selector: 'jad-form-fields-text',
    templateUrl: './form-fields-text.component.html',
    styleUrls: ['./form-fields-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class JadFormFieldsTextComponent extends BaseTextFormFieldComponent {
  @Input() type: 'text' | 'number' | 'email' = 'text';

  @Input() hint: string;

  Validators = Validators;
}
